export const tableOption = {
    border: true,
    index: true,
    indexLabel: '序号',
    stripe: true,
    menuAlign: 'center',
    menuWidth: 150,
    align: 'center',
    refreshBtn: true,
    showClomnuBtn: false,
    searchSize: 'mini',
    searchMenuSpan: 6,
    addBtn: false,
    editBtn: false,
    viewBtn: false,
    delBtn: false,
    menu: false,
    props: {
        label: 'label',
        value: 'value'
    },
    column: [{
        label: '标题',
        overHidden: true,
        prop: 'title'
    }, {
        label: 'IP地址',
        prop: 'remoteAddr'
    }, {
        label: '请求方式',
        prop: 'method'
    }, {
        label: '操作人',
        prop: 'createBy'
    }, {
        label: '操作时间',
        prop: 'createTime',
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        search: false,
    }]
}