<template>
  <div class="settingManagement">
    <Jump :jumpList="jumpList" />
    <NavMenu :activeUrl="activeUrl" />
    <div class="page-wrap">
      <chil-menu />
      <div class="log-container">
        <div class="search">
          <el-input
                  v-model="search.dateTitle"
                  placeholder="输入日志标题"
                  clearable
                  style="margin-left: 45px"
          />
          <el-input
                  v-model="search.datePeople"
                  placeholder="输入日志操作人"
                  clearable
          />
          <el-date-picker
                  v-model="dateTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  align="left"
                  @change="changeDate"
          />
          <el-button type="primary" icon="el-icon-search" @click="getList(page)"
          >查询</el-button
          >
        </div>
        <avue-crud
                ref="crud"
                :page.sync="page"
                :data="tableData"
                :table-loading="tableLoading"
                :option="tableOption"
                @on-load="getList"
                @search-change="searchChange"
                @refresh-change="refreshChange"
                @size-change="sizeChange"
                @current-change="currentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchLogList } from "@/api/setting";
import NavMenu from "../navMenu.vue";
import Jump from "@/components/jump";
import { tableOption } from "./tableOption.js";
import ChilMenu from "../chilMenu.vue";

export default {
  name: "settingManagement",
  components: {
    ChilMenu,
    Jump,
    NavMenu,
  },
  data() {
    return {
      activeUrl: "/setting/system",
      jumpList: [
        { name: "首页",},
        { name: "系统管理"},
        { name: "日志管理"},
      ],
      tableData: [],
      searchForm: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
      },
      tableLoading: false,
      tableOption: tableOption,
      search: {
        //查询条件
        dateTitle: null,
        // datePeople: null,
        // startTime: null,
        // endTime: null
      },
      dateTime: "",
    };
  },
  methods: {
    getList(page, params) {
      this.tableLoading = true;
      let data = {}
      if(this.search.dateTitle != null){
        data.title = this.search.dateTitle
      }
      if(this.search.datePeople != null){
        data.createBy = this.search.datePeople
      }
      if(this.search.startTime != null){
        data.startTime = this.search.startTime,
        data.endTime = this.search.endTime
      }
      fetchLogList(
        Object.assign(
          {
            descs: "create_time",
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm,
          data
        )
      ).then((response) => {
        this.tableData = response.data.records;
        this.page.total = response.data.total;
        this.tableLoading = false;
      });
    },
    searchChange(form, done) {
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    refreshChange() {
      this.getList(this.page);
    },
    changeDate(date) {
      if (date) {
        (this.search.startTime = `${date[0]} 00:00:00`),
          (this.search.endTime = `${date[1]} 23:59:59`);
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
    },
  },
};
</script>

<style lang="scss">
.settingManagement{
  padding: 0 10px;
}
.page-wrap {
  display: flex;
  .log-container {
    background: transparent;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    width: 0;
  }
}
.log-container {
  margin-top: 20px;
  .user-wrap {
    display: flex;
    .user-tree {
      width: 230px;
      flex-shrink: 0;
      padding-top: 20px;
    }
  }
  .user {
    height: 100%;

    &__tree {
      padding-top: 3px;
      padding-right: 20px;
    }

    &__main {
      .el-card__body {
        padding-top: 0;
      }
    }
  }
  .el-tree {
    padding-top: 15px;
    height: calc(100vh - 300px);
    background: #0c265a;
  }
  .el-card {
    background-color: transparent;
    border: none;
  }
  .avue-crud {
    background: transparent;
  }
  .avue-crud__menu {
    min-height: 0px;
    background: transparent;
  }
  ::v-deep .single-row {
    font-family: "MicrosoftYaHei";
  }
  ::v-deep .double-row {
    background: #0c265a !important;
    color: #ffffff;
    font-size: 14px;
    font-family: "MicrosoftYaHei";
    font-weight: 400;
  }
  .avue-crud .el-table th {
    background: rgb(17, 50, 117);
    color: rgb(163, 214, 255);
    border: none;
  }
  .el-table tr {
    color: #ffffff;
    background-color: #081e49;
  }
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #0c265a;
    border-bottom: 1px solid #1d3b6a;
    border-top: 1px solid #1d3b6a;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }
  .el-table th.el-table__cell {
    color: #a3d6ff;
    background-color: #113275;
  }
  .el-table td.el-table__cell div {
    white-space: nowrap;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before,
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    background-color: #1d3b6a;
  }
  .el-table--border,
  .el-table--group {
    border: none;
    margin-top: 20px;
  }
  .el-table__body tr.hover-row.current-row > td.el-table__cell,
  .el-table__body
    tr.hover-row.el-table__row--striped.current-row
    > td.el-table__cell,
  .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #113275 !important;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .el-pagination__total {
    color: #a3d6ff;
  }
  .el-tag {
    border-color: #1d3b6a;
    background-color: transparent;
  }
  .avue-crud {
    width: 95%;
  }

  // button {
  //   height: 36px;
  //   background: linear-gradient(0deg, #1f79ff, #23b4ff);
  //   border-radius: 2px;
  //   line-height: 10px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .el-button--small.is-circle {
    display: none;
  }
  // .el-card__body {
  //   padding-top: 20px !important;
  // }
  .el-scrollbar__wrap {
    overflow: auto;
  }
  .el-radio.is-bordered {
    border: 1px solid rgba(73, 108, 180, 0.36) !important;
  }
  .el-radio {
    color: #a3d6ff;
  }

  // .el-select-dropdown__list{
  //   background: #06193C;
  // }
  .el-pagination__jump {
    color: #a3d6ff;
  }
  .el-table__empty-block {
    background: #0c265a;
  }
  .avue-empty__desc {
    color: #a3d6ff;
  }
  .avue-empty__image img {
    display: none;
  }
  .avue-empty__image {
    height: 0px !important;
  }
}
.el-select-dropdown__list {
  background: #06193c;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #0c265a;
}
.avue-dialog .el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.avue-dialog .el-dialog__header {
  background: url("../../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.avue-dialog .el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.avue-dialog .el-dialog__body {
  background: #0c265a;
}
.avue-dialog .avue-dialog__footer {
  background: #0c265a;
  border: none !important;
}
.el-collapse-item__wrap {
  background: transparent;
}
.avue-group .el-collapse,
.avue-group .el-collapse-item__wrap {
  border-color: transparent;
}
.avue-form__group--flex {
  .el-form-item__label {
    color: #a3d6ff;
  }
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    // display: flex;
  }
}
.search {
  display: flex;
  margin-top: 15px;
  .el-input {
    width: 200px;
    margin-right: 10px;
  }
  .el-range-editor.el-input__inner {
    margin-right: 10px;
     .el-range-input {
      background-color: #06193c;
      color: rgba(163, 214, 255, 0.36) !important;
    }
  }
  .el-date-editor .el-range-separator {
    color: rgba(163, 214, 255, 0.76);
  }
  .el-date-editor .el-range__icon {
    color: rgba(163, 214, 255, 0.76);
  }
}
.el-date-editor {
  .el-range-input {
  color: rgba(163, 214, 255, 0.36);
  &::-webkit-input-placeholder {
    color: rgba(163, 214, 255, 0.36);
  }
}
}

</style>
